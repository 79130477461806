import { createTheme, createThemedStyled, createThemedUseStyletron, createThemedWithStyle } from 'baseui'
import { basicSwatches, statusSwatches, visualSwatches } from './primitives'
import { breakpoints, type Breakpoint } from './responsive'

const primitives = {
  ...basicSwatches,
  ...statusSwatches,
  ...visualSwatches,
}

const generalColors = {
  disabled: primitives.grayDark,
  spinnerTrackFill: primitives.grayLightest,
  contentPrimary: primitives.primary,
}

const buttonColors = {
  buttonDisabledFill: primitives.grayLightest,
  buttonDisabledText: primitives.grayDark,

  buttonPrimaryFill: primitives.primary,
  buttonPrimaryText: primitives.white,
  buttonPrimaryHover: primitives.primaryLight,
  buttonPrimaryActive: primitives.primaryLighter,
  buttonPrimarySpinnerForeground: primitives.primary,
  buttonSecondaryFill: primitives.white,
  buttonSecondaryText: primitives.primary,
  buttonSecondaryHover: primitives.white,
  // Believe it or not using the exact same hex code as the primary button
  // destroys button styling, this seems to prevent it somehow.
  buttonSecondaryActive: primitives.primaryLighter.replace(/7$/, '8'),
  buttonSecondarySpinnerForeground: primitives.primary,
  buttonTertiaryFill: primitives.grayLighter,
  buttonTertiaryText: primitives.primary,
  buttonTertiaryHover: primitives.grayLight,
  buttonTertiaryActive: primitives.gray,

  /* Custom button kinds */
  buttonNegativeFill: primitives.redDark,
  buttonNegativeText: primitives.white,
  buttonNegativeHover: primitives.redDarker,
  buttonNegativeActive: primitives.redDarkest,

  buttonPositiveFill: primitives.greenDark,
  buttonPositiveText: primitives.white,
  buttonPositiveHover: primitives.greenDarker,
  buttonPositiveActive: primitives.greenDarkest,

  buttonMinimalFill: 'transparent',
  buttonMinimalText: primitives.primary,
  buttonMinimalHover: primitives.grayLightest,
  buttonMinimalActive: primitives.grayLight,
}

const tickColors = {
  tickFillHover: primitives.grayLightest,
  tickFillActive: primitives.blueLightest,
  tickFillSelected: primitives.accent,
  tickFillSelectedHover: primitives.accent,
  tickFillSelectedHoverActive: primitives.accent,
  tickFillError: primitives.errorLightest,
  tickFillErrorHover: primitives.errorLightest,
  tickFillErrorActive: primitives.errorLighter,
  tickFillErrorHoverActive: primitives.errorLighter,
  tickFillErrorSelected: primitives.errorDark,
  tickFillErrorSelectedHover: primitives.errorDark,
  tickFillErrorSelectedHoverActive: primitives.errorDark,
  tickFillDisabled: primitives.grayLight,
  tickBorder: primitives.grayLight,
  tickBorderError: primitives.errorDarkest,
}

const tooltipColors = {
  tooltipBackground: primitives.primary,
}

const inputColors = {
  inputBorder: primitives.grayLight,
  inputBorderActive: primitives.accent,
  inputBorderError: primitives.errorDarkest,
  inputBorderDisabled: primitives.grayLight,
  inputFill: primitives.white,
  inputFillActive: primitives.white,
  inputFillError: primitives.errorLightest,
  inputFillDisabled: primitives.grayLightest,
  inputEnhancerFill: primitives.grayLightest,
  inputTextDisabled: primitives.grayDark,
  inputPlaceholder: primitives.grayDark,
}

const linkColors = {
  linkText: primitives.accent,
  linkVisited: primitives.accent,
  linkHover: primitives.accent,
  linkActive: primitives.accent,
}

const borderColors = {
  borderSelected: primitives.accent,
  borderOpaque: primitives.grayLight,
  borderNegative: primitives.redDarker,
}

const backgroundColors = {
  backgroundPrimary: primitives.white,
  backgroundSecondary: primitives.grayLightest,
}

const modalColors = {
  modalHeader: primitives.primary,
  modalText: primitives.primary,
}

const notificationColors = {
  notificationNegativeText: primitives.errorDarkest,
  notificationNegativeBackground: primitives.errorLightest,

  notificationPositiveText: primitives.successDarkest,
  notificationPositiveBackground: primitives.successLightest,

  notificationWarningText: primitives.warningDarkest,
  notificationWarningBackground: primitives.warningLightest,

  notificationInfoText: primitives.informationDarkest,
  notificationInfoBackground: primitives.informationLightest,
}

const tagColors = {
  tagPositiveLightFont: primitives.successDarkest,
  tagPositiveLightBackground: primitives.successLightest,
  tagPositiveSolidFont: primitives.white,
  tagPositiveSolidBackground: primitives.successDarkest,
  tagPositiveOutlinedFont: primitives.successDarkest,
  tagPositiveOutlinedBackground: primitives.successDarkest,

  tagNegativeLightFont: primitives.errorDarkest,
  tagNegativeLightBackground: primitives.errorLightest,
  tagNegativeSolidFont: primitives.white,
  tagNegativeSolidBackground: primitives.errorDarkest,
  tagNegativeOutlinedFont: primitives.errorDarkest,
  tagNegativeOutlinedBackground: primitives.errorDarkest,

  tagAccentSolidFont: primitives.informationDarkest,
  tagAccentLightFont: primitives.informationDarkest,
  tagAccentLightBackground: primitives.informationLightest,
  tagAccentSolidBackground: primitives.informationDarkest,
  tagAccentOutlinedFont: primitives.informationDarkest,
  tagAccentOutlinedBackground: primitives.informationDarkest,

  tagWarningLightFont: primitives.orangeDarkest,
  tagWarningLightBackground: primitives.warningLightest,
  tagWarningSolidBackground: primitives.warningDarkest,
  tagWarningOutlinedFont: primitives.warningDarkest,
  tagWarningOutlinedBackground: primitives.warningDarkest,

  tagNeutralLightFont: primitives.primary,
  tagNeutralLightBackground: primitives.grayLighter,
  tagNeutralSolidFont: primitives.primary,
  tagNeutralSolidBackground: primitives.grayDarker,
  tagNeutralOutlinedFont: primitives.primary,
  tagNeutralOutlinedBackground: primitives.grayDark,

  // Extended tag kinds
  tagPrimaryLightFont: primitives.primary,
  tagPrimaryLightBackground: primitives.primary,
  tagPrimarySolidFont: primitives.primary,
  tagPrimarySolidBackground: primitives.primary,
  tagPrimaryOutlinedFont: primitives.primary,
  tagPrimaryOutlinedBackground: primitives.primary,

  tagSecondaryLightFont: primitives.primary,
  tagSecondaryLightBackground: primitives.secondary,
  tagSecondarySolidFont: primitives.primary,
  tagSecondarySolidBackground: primitives.secondary,
  tagSecondaryOutlinedFont: primitives.primary,
  tagSecondaryOutlinedBackground: primitives.secondaryDarker,

  tagSecondaryDarkLightFont: primitives.primary,
  tagSecondaryDarkLightBackground: primitives.secondary,
  tagSecondaryDarkSolidFont: primitives.primary,
  tagSecondaryDarkSolidBackground: primitives.secondary,
  tagSecondaryDarkOutlinedFont: primitives.primary,
  tagSecondaryDarkOutlinedBackground: primitives.secondaryDarker,

  // Extended tag security type kinds
  tagOptionLightFont: primitives.primary,
  tagOptionLightBackground: primitives.crayola,
  tagOptionSolidFont: primitives.crayolaDarkest,
  tagOptionSolidBackground: primitives.crayolaLightest,
  tagOptionOutlinedFont: primitives.crayolaDarkest,
  tagOptionOutlinedBackground: primitives.crayolaDarkest,

  tagWarrantLightFont: primitives.primary,
  tagWarrantLightBackground: primitives.purple,
  tagWarrantSolidFont: primitives.purpleDarkest,
  tagWarrantSolidBackground: primitives.purpleLightest,
  tagWarrantOutlinedFont: primitives.purpleDarkest,
  tagWarrantOutlinedBackground: primitives.purpleDarkest,

  tagPhantomshareLightFont: primitives.primary,
  tagPhantomshareLightBackground: primitives.pink,
  tagPhantomshareSolidFont: primitives.pinkDarkest,
  tagPhantomshareSolidBackground: primitives.pinkLightest,
  tagPhantomshareOutlinedFont: primitives.pinkDarkest,
  tagPhantomshareOutlinedBackground: primitives.pinkDarkest,

  tagRsuLightFont: primitives.primary,
  tagRsuLightBackground: primitives.turquoise,
  tagRsuSolidFont: primitives.turquoiseDarkest,
  tagRsuSolidBackground: primitives.turquoiseLightest,
  tagRsuOutlinedFont: primitives.turquoiseDarkest,
  tagRsuOutlinedBackground: primitives.turquoiseDarkest,

  tagSharegrantLightFont: primitives.primary,
  tagSharegrantLightBackground: primitives.cyan,
  tagSharegrantSolidFont: primitives.cyanDarkest,
  tagSharegrantSolidBackground: primitives.cyanLightest,
  tagSharegrantOutlinedFont: primitives.cyanDarkest,
  tagSharegrantOutlinedBackground: primitives.cyanDarkest,

  tagShareLightFont: primitives.primary,
  tagShareLightBackground: primitives.blue,
  tagShareSolidFont: primitives.blueDarkest,
  tagShareSolidBackground: primitives.blueLightest,
  tagShareOutlinedFont: primitives.blueDarkest,
  tagShareOutlinedBackground: primitives.blueDarkest,

  tagConvertiblenoteLightFont: primitives.primary,
  tagConvertiblenoteLightBackground: primitives.orange,
  tagConvertiblenoteSolidFont: primitives.orangeDarkest,
  tagConvertiblenoteSolidBackground: primitives.orangeLightest,
  tagConvertiblenoteOutlinedFont: primitives.orangeDarkest,
  tagConvertiblenoteOutlinedBackground: primitives.orangeDarkest,

  // Extended tag share class kind
  tagShareclassLightFont: primitives.primary,
  tagShareclassLightBackground: primitives.grayLighter,
  tagShareclassSolidFont: primitives.primary,
  tagShareclassSolidBackground: primitives.grayLighter,
  tagShareclassOutlinedFont: primitives.grayDarker,
  tagShareclassOutlinedBackground: primitives.grayDarker,
}

const toastColors = {
  toastText: primitives.primary,

  toastPositiveBackground: primitives.white,
  toastIconPositiveBackground: primitives.successLightest,
  toastIconPositive: primitives.successDarkest,

  toastNegativeBackground: primitives.white,
  toastIconNegativeBackground: primitives.errorLightest,
  toastIconNegative: primitives.errorDarkest,

  toastInfoBackground: primitives.white,
  toastIconInfoBackground: primitives.informationLightest,
  toastIconInfo: primitives.informationDarkest,
}

const tableColors = {
  tableBackground: primitives.white,
  tableBorderColor: primitives.grayLight,
  tableHeadFontColor: primitives.primary,
  tableVerticalHeaderColor: primitives.grayLightest,
}

const borderRadius = {
  progressBarRadius: '34px',
  buttonBorderRadius: '38px',
  inputBorderRadius: '4px',
  surfaceBorderRadius: '6px',
  popoverBorderRadius: '6px',
  tagBorderRadius: '4px',
}

const borderOverrides: { inputEnhancerBorder?: string } = {
  inputEnhancerBorder: undefined,
}

const iconColors = {
  iconDefaultFillColor: primitives.grayDarker,
  iconActiveFillColor: primitives.accent,
}

const datePickerColors = {
  calendarDayBackgroundPseudoSelectedHighlighted: primitives.grayLightest,
  calendarDayBackgroundSelectedHighlighted: primitives.accent,
  calendarBackground: primitives.white,
  calendarForeground: primitives.primary,
  calendarHeaderBackground: primitives.white,
  calendarHeaderForeground: primitives.primary,
}

const menuOverrides = {
  menuFill: primitives.white,
  menuFillHover: primitives.grayLightest,
  menuFontDefault: primitives.primary,
  menuFontDisabled: primitives.grayDark,
  menuFontHighlighted: primitives.primary,
  menuFontSelected: primitives.primary,
}

// Create media queries based on the breakpoints
const ResponsiveTheme = Object.keys(breakpoints).reduce<{
  breakpoints: typeof breakpoints
  mediaQuery: Record<string, string>
}>(
  (acc, key) => {
    acc.mediaQuery[key] = `@media screen and (min-width: ${breakpoints[key as Breakpoint]}px)`
    return acc
  },
  {
    breakpoints,
    mediaQuery: {},
  }
)

const colors = {
  ...generalColors,
  ...buttonColors,
  ...inputColors,
  ...borderColors,
  ...iconColors,
  ...linkColors,
  ...backgroundColors,
  ...modalColors,
  ...notificationColors,
  ...tickColors,
  ...tooltipColors,
  ...tagColors,
  ...toastColors,
  ...tableColors,
  ...datePickerColors,
  ...menuOverrides,
  ...primitives,
}

const Inter = '"Inter", system-ui, Arial, sans-serif'
const Inconsolata = '"Inconsolata", monospace'

export const overrides = {
  borders: {
    ...borderOverrides,
    ...borderRadius,
  },
  colors,
  effects: {
    drawerBoxShadow: '-15px -5px 50px rgb(0 0 0 / 20%)',
    cardBoxShadow: '0px -1px 7px rgba(0, 0, 0, 0.02), 0px 5px 6px rgba(0, 0, 0, 0.04)',
    cardTitleBoxShadow: '0px -1px 7px rgba(0, 0, 0, 0.02)',
  },
  tables: {
    border: `1px solid ${tableColors.tableBorderColor}`,
    nestedBorder: `1px dashed ${tableColors.tableBorderColor}`,
    innerPadding: `0 16px`,
    vertical: {
      cellPadding: '10px 12px',
      headerCellPadding: '10px 12px',
    },
    horizontal: {
      cellPadding: '12px 16px 12px 0',
      headerCellPadding: '10px 16px 10px 0',
    },
  },
  typography: {
    primaryFontFamily: Inter,
    secondaryFontFamily: Inconsolata,
    // This is the same as HeadingLarge, it's only different in Carta theme
    HeadingXLarge: {
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 700,
      fontFamily: Inter,
    },
    HeadingLarge: {
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 700,
      fontFamily: Inter,
    },
    HeadingMedium: {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 700,
      fontFamily: Inter,
    },
    HeadingSmall: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 700,
      fontFamily: Inter,
    },
    HeadingXSmall: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 700,
      fontFamily: Inter,
    },
    ParagraphMedium: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      fontFamily: Inter,
    },
    ParagraphSmall: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      fontFamily: Inter,
    },
    ParagraphXSmall: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      fontFamily: Inter,
    },
    // This is the same as LabelMedium, it's only different in Carta theme
    LabelLarge: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      fontFamily: Inter,
    },
    LabelMedium: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      fontFamily: Inter,
    },
    LabelSmall: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      fontFamily: Inter,
    },
    LabelXSmall: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      fontFamily: Inter,
    },
  },
  ...ResponsiveTheme,
}

// Extend the Theme type by casting, because createTheme's return type is
// stricter now and doesn't include our overrides
const BaseTheme = createTheme(overrides)
export type ThemeT = typeof BaseTheme & typeof overrides
export const Theme = BaseTheme as ThemeT

export const useStyletron = createThemedUseStyletron<ThemeT>()
export const withStyle = createThemedWithStyle<ThemeT>()
export const styled = createThemedStyled<ThemeT>()
